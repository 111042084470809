import * as React from "react"
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { InputGroup } from 'react-bootstrap';
import { FormControl } from 'react-bootstrap';
import Layout from "../components/layout"
import Seo from "../components/seo"

import sessions from "../../data/sessions.json"
import SessionList2 from "./sessionlist2";
import * as calendar from './../helper/calendar.js';

const Search = (props) => { 
    const [query, setQuery] = useState();
    const [displayList, setDisplayList] = useState();
   
    function createICS() {
        let test = calendar.createIcs(displayList);       
        const element = document.createElement("a");
        const file = new Blob([test], {
            type: "text/plain"
        });
        element.href = URL.createObjectURL(file);
        element.download = "rp23.ics";
        document.body.appendChild(element);
        element.click();
    }

    useEffect(() => {
    
        let selectedSessions = [];
        if (typeof window !== `undefined`) {    
            selectedSessions = JSON.parse(window.localStorage.getItem('selectedSessions'));
        }
        
        let merged = [];

        if(query) {
            merged = sessions.data.map(t1 => ({...t1, ...selectedSessions?.find(t2 => t2.id === t1.id)}));
            merged = merged.filter((obj) =>
                JSON.stringify(obj).toLowerCase().includes(query.toLowerCase())
            );
            merged = merged?.sort(function(a,b){return a.begin > b.begin ? 1 : a.begin < b.begin ? -1 : 0 });
        }

        setDisplayList(merged);
    }, [query]);

  return (
  <Layout>
    <Seo title="Search" />
    <React.Fragment>
        <h1>Search</h1>
        <Form>            
            <InputGroup className="mb-3">
                <FormControl
                    placeholder="Any way the wind blows"
                    aria-label="Query"
                    aria-describedby="basic-addon2"
                    value={query}
                    onChange={(element) => setQuery(element.target.value)}
                />    
            </InputGroup>
        </Form>  
        <p>{displayList?.length} matching</p>
        {
        (displayList)
        ?
        <React.Fragment>
            <SessionList2 displayList={displayList}></SessionList2>                        
        </React.Fragment>
        :
        <span></span>
        }
        { displayList && displayList.length > 0 
        ?
        <Button onClick={createICS} variant="outline-secondary">Calendar (ICS)</Button>
        :
        <React.Fragment></React.Fragment>
        }
    </React.Fragment>
  </Layout>
)}

export default Search